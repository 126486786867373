// Variables
$white: #f7f7f7;
$black: #000000;

$button-primary: #d83c3c;
$input-placeholder: #999;

// Fonts
$work-sans: 'Work Sans', sans-serif;
$source-sans: 'Source Sans Pro', sans-serif;

$menu-active: #d80024;
