.cf:before, .cf:after {
  content: "";
  display: table;
}

.cf:after,
.bottom:after {
  clear: both;
}

.cf {
  zoom: 1;
}

.form-wrapper {
  position: relative;
  width: 100%;
  padding: 15px;
  background: #444;
  background: rgba(255, 255, 255, .1);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, .4) inset, 0 1px 0 rgba(255, 255, 255, .2);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .4) inset, 0 1px 0 rgba(255, 255, 255, .2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .4) inset, 0 1px 0 rgba(255, 255, 255, .2);
}

.form-wrapper input {
  width: 80%;
  height: 40px;
  padding: 10px 5px;
  float: left;
  font: normal 18px $source-sans;
  border: 0;
  background: #eee;
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  margin-bottom: 0;
  font-size: 18px;
  letter-spacing: 2px;
}

.form-wrapper input:focus {
  outline: 0;
  background: #fff;
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, .8) inset;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .8) inset;
  box-shadow: 0 0 2px rgba(0, 0, 0, .8) inset;
}

.form-wrapper input::-webkit-input-placeholder {
  color: $input-placeholder;
  font-weight: normal;
}

.form-wrapper input:-moz-placeholder {
  color: $input-placeholder;
  font-weight: normal;
}

.form-wrapper input:-ms-input-placeholder {
  color: $input-placeholder;
  font-weight: normal;
}

.form-wrapper input[type='submit'] {
  overflow: visible;
  position: relative;
  float: right;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 20%;
  font: bold 18px/40px $source-sans;
  color: #fff;
  text-transform: uppercase;
  background: $button-primary;
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);

  @include breakpoint(small down) {
    font-size: 12px;
    letter-spacing: 0;
  }
}

.form-wrapper input[type='submit']:hover {
  background: #e54040;
}

.form-wrapper input[type='submit']:active,
.form-wrapper input[type='submit']:focus {
  background: #c42f2f;
}

.form-wrapper input[type='submit']:before {
  content: '';
  position: absolute;
  border-width: 8px 8px 8px 0;
  border-style: solid solid solid none;
  border-color: transparent $button-primary transparent;
  top: 12px;
  left: -6px;
}

.form-wrapper input[type='submit']:hover:before {
  border-right-color: #e54040;
}

.form-wrapper input[type='submit']:focus:before {
  border-right-color: #c42f2f;
}

.form-wrapper input[type='submit']::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.form-wrapper {
  .error-wrapper {
    margin-top: 5em;
    margin-bottom: 0;

    .error {
      color: $primary-color;
    }
  }
}

.form-item-microchip-number { margin: 0 }

#character-ctr {
  position: absolute;
  top: 22px;
  right: 24%;
  color: black;

  span {
    color: $primary-color;
    font: normal 18px $source-sans;
  }
  span[data-ctr="9"], span[data-ctr="10"], span[data-ctr="15"] {
    color: #2cbb61;
  }
}
